import { Component, Input } from '@angular/core';
import { SOSCustomer } from '../sos-customer';

@Component({
  selector: 'app-sos-customer-list',
  templateUrl: './sos-customer-list.component.html',
  styleUrls: ['./sos-customer-list.component.scss'],
})
export class SosCustomerListComponent {
  @Input('didSearch') didSearch: boolean = false;
  @Input('sosCustomers') sosCustomers: SOSCustomer[] | undefined;

  getMailToLink() {
    return (
      'mailto:?bcc=' +
      this.sosCustomers! //
        .map((sosCustomer) => sosCustomer.emailAddress) //
        .join(';')
    );
  }

  downloadEmailAddresses() {
    const content = this.sosCustomers! //
      .map((customer) => customer.emailAddress) //
      .join('\n');

    const blob = new Blob([content], { type: 'text/plain' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0];

    link.download = `sos-email-adresses-${formattedDate}.txt`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  }
}
