import { Component, Inject, Input } from '@angular/core';
import {
  BASE_FRESHDESK_URL,
  FRESHDESK_SERVICE_URL,
} from 'src/app/injection-tokens';
import { OrderDetails } from '../order-details';

@Component({
  selector: 'app-new-manco-order-info',
  templateUrl: './new-manco-order-info.component.html',
  styleUrls: ['./new-manco-order-info.component.scss'],
})
export class NewMancoOrderInfoComponent {
  @Input() orderDetails!: OrderDetails;
  @Input() freshdeskTicketNumber?: string;

  constructor(
    @Inject(FRESHDESK_SERVICE_URL) readonly freshdeskServiceUrl: string,
    @Inject(BASE_FRESHDESK_URL) readonly baseFreshdeskUrl: string
  ) {}
}
