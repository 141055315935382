<div *ngIf="specials.length > 0">
  <div class="d-flex justify-content-end mb-3">
    <div class="searchbar">
      <input
        class="search_input"
        type="text"
        [(ngModel)]="searchText"
        (ngModelChange)="onSearchTextChange()"
        placeholder="Zoeken.."
      />
      <div class="search_icon"><i class="fas fa-search"></i></div>
    </div>
  </div>
  <table class="table table-hover fixed-table-layout">
    <thead>
      <tr>
        <th
          scope="col"
          class="width-10 sort"
          (click)="sortByField('sku', !SortBySkuAsc)"
        >
          <span class="d-xxl-none me-1">SKU</span>
          <span class="d-none d-xxl-inline me-1">Artikelnummer</span>
          <div
            #sortIcons
            id="skuAsc"
            class="fa-solid fa-sort-up sort-icon"
          ></div>
          <div
            #sortIcons
            id="skuDesc"
            class="fa-solid fa-sort-down sort-icon"
          ></div>
        </th>
        <th
          scope="col"
          class="width-15 sort"
          (click)="sortByField('description', !sortByDescriptionAsc)"
        >
          Omschrijving
          <div
            #sortIcons
            id="descriptionAsc"
            class="fa-solid fa-sort-up sort-icon"
          ></div>
          <div
            #sortIcons
            id="descriptionDesc"
            class="fa-solid fa-sort-down sort-icon"
          ></div>
        </th>
        <th
          scope="col"
          class="width-7 sort"
          (click)="sortByField('class2', !sortByClass2Asc)"
        >
          Class2
          <div
            #sortIcons
            id="class2Asc"
            class="fa-solid fa-sort-up sort-icon"
          ></div>
          <div
            #sortIcons
            id="class2Desc"
            class="fa-solid fa-sort-down sort-icon"
          ></div>
        </th>
        <th
          scope="col"
          class="width-10 sort"
          (click)="sortByField('supplierSku', !sortBySupplierSkuAsc)"
        >
          <span class="d-xxl-none me-1">Lev. SKU</span>
          <span class="d-none d-xxl-inline me-1">Lev. artikelnummer</span>
          <div
            #sortIcons
            id="supplierSkuAsc"
            class="fa-solid fa-sort-up sort-icon"
          ></div>
          <div
            #sortIcons
            id="supplierSkuDesc"
            class="fa-solid fa-sort-down sort-icon"
          ></div>
        </th>
        <th
          scope="col"
          class="width-7 sort"
          (click)="sortByField('orderUnit', !orderUnitAsc)"
        >
          <span class="d-xxl-none me-1">Eenheid</span>
          <span class="d-none d-xxl-inline me-1">Besteleenheid</span>

          <div
            #sortIcons
            id="orderUnitAsc"
            class="fa-solid fa-sort-up sort-icon"
          ></div>
          <div
            #sortIcons
            id="orderUnitDesc"
            class="fa-solid fa-sort-down sort-icon"
          ></div>
        </th>
        <th
          scope="col"
          class="width-7 sort"
          (click)="sortByField('supplierPrice', !sortBySupplierPriceAsc)"
        >
          <span class="d-xxl-none me-1">Cost €</span>
          <span class="d-none d-xxl-inline me-1">Inkoopprijs</span>
          <div
            #sortIcons
            id="supplierPriceAsc"
            class="fa-solid fa-sort-up sort-icon"
          ></div>
          <div
            #sortIcons
            id="supplierPriceDesc"
            class="fa-solid fa-sort-down sort-icon"
          ></div>
        </th>
        <th
          scope="col"
          class="width-7 sort"
          (click)="sortByField('salesPrice', !sortBySalesPriceAsc)"
        >
          <span class="d-xxl-none me-1">Sales €</span>
          <span class="d-none d-xxl-inline me-1">Verkoopprijs</span>
          <div
            #sortIcons
            id="salesPriceAsc"
            class="fa-solid fa-sort-up sort-icon"
          ></div>
          <div
            #sortIcons
            id="salesPriceDesc"
            class="fa-solid fa-sort-down sort-icon"
          ></div>
        </th>
        <th
          scope="col"
          class="width-10 sort"
          (click)="sortByField('creationBy', !sortByCreationByAsc)"
        >
          <span class="d-xxl-none me-1">Aang. door</span>
          <span class="d-none d-xxl-inline me-1">Aangemaakt door</span>
          <div
            #sortIcons
            id="creationByAsc"
            class="fa-solid fa-sort-up sort-icon"
          ></div>
          <div
            #sortIcons
            id="creationByDesc"
            class="fa-solid fa-sort-down sort-icon"
          ></div>
        </th>
        <th
          scope="col"
          class="width-10 sort"
          (click)="sortByField('creationDate', !sortByCreationDateAsc)"
        >
          <span class="d-xxl-none me-1">Aang. op</span>
          <span class="d-none d-xxl-inline me-1">Aangemaakt op</span>
          <div
            #sortIcons
            id="creationDateAsc"
            class="fa-solid fa-sort-up sort-icon"
          ></div>
          <div
            #sortIcons
            id="creationDateDesc"
            class="fa-solid fa-sort-down sort-icon"
          ></div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="
          let special of specials
            | searchTUSpecials : searchText
            | paginate : { itemsPerPage: 10, currentPage: page }
        "
      >
        <th scope="row">{{ special.sku }}</th>
        <td>{{ special.description }}</td>
        <td>{{ special.class2 }}</td>
        <td>{{ special.supplierSKU }}</td>
        <td>{{ special.orderUnit }}</td>
        <td>{{ special.supplierPrice }}</td>
        <td>{{ special.salesPrice }}</td>
        <td>{{ special.creationBy }}</td>
        <td>
          {{ special.creationDate! | utcDate : "yyyy-MM-dd HH:mm:ss" }}
        </td>
      </tr>
    </tbody>
  </table>
  <pagination-controls
    (pageChange)="page = $event"
    previousLabel="Vorige"
    nextLabel="Volgende"
    [autoHide]="true"
  ></pagination-controls>
</div>
