<form [formGroup]="form" (ngSubmit)="onSubmit()" class="needs-validation">
  <div class="mb-4">
    <!-- Order Number -->
    <div class="row align-items-center mb-2">
      <label for="ordernumber">Ordernummer</label>
      <div>
        <input
          type="text"
          class="form-control form-control-sm"
          [ngClass]="
            form.get('orderNumber')?.touched
              ? form.get('orderNumber')?.valid
                ? 'is-valid'
                : 'is-invalid'
              : ''
          "
          id="ordernumber"
          formControlName="orderNumber"
        />
        <div
          *ngIf="
            form.get('orderNumber')?.invalid &&
            (form.get('orderNumber')?.dirty || form.get('orderNumber')?.touched)
          "
          class="text-danger"
        >
          <div *ngIf="form.get('orderNumber')?.errors?.['required']">
            Ordernummer is verplicht.
          </div>
          <div *ngIf="form.get('orderNumber')?.errors?.['pattern']">
            Ordernummer moet een INDI ordernummer zijn.
          </div>
        </div>
      </div>
    </div>

    <!-- Freshdesk Ticket Number -->
    <div class="row align-items-center mb-2">
      <label for="freshdeskticketnumber">Freshdesk ticketnummer</label>
      <div>
        <input
          type="text"
          class="form-control form-control-sm"
          [ngClass]="
            form.get('freshdeskTicketNumber')?.touched
              ? form.get('freshdeskTicketNumber')?.valid
                ? 'is-valid'
                : 'is-invalid'
              : ''
          "
          id="freshdeskticketnumber"
          formControlName="freshdeskTicketNumber"
        />
        <div
          *ngIf="
            form.get('freshdeskTicketNumber')?.invalid &&
            (form.get('freshdeskTicketNumber')?.dirty ||
              form.get('freshdeskTicketNumber')?.touched)
          "
          class="text-danger"
        >
          <div *ngIf="form.get('freshdeskTicketNumber')?.errors?.['required']">
            Freshdesk ticket is verplicht.
          </div>
          <div *ngIf="form.get('freshdeskTicketNumber')?.errors?.['pattern']">
            Freshdesk ticket mogen alleen cijfers zijn.
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <button
      type="reset"
      class="btn btn-outline-danger me-2"
      (click)="onReset()"
    >
      Wissen
    </button>

    <button type="submit" class="btn btn-primary" [disabled]="!form.valid">
      Zoeken
    </button>
  </div>
</form>
