import { Component, ViewChild } from '@angular/core';
import { FeedbackComponent } from 'src/app/general-components/feedback/feedback.component';
import { FeedbackType } from 'src/app/general-components/feedback/feedback-type';
import { NewMancoService } from './new-manco.service';
import { OrderDetails } from './order-details';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-new-manco',
  templateUrl: './new-manco.component.html',
  styleUrls: ['./new-manco.component.scss'],
})
export class NewMancoComponent {
  isACCEnvironment: boolean =
    environment.baseIndiUrl === 'https://webshop-10.acc.indicloud.net/'; //TODO remove when upgrading this
  orderNumber: string = '';
  freshdeskTicketNumber: string = '';
  orderDetails?: OrderDetails;

  @ViewChild(FeedbackComponent) feedbackComponent!: FeedbackComponent;

  isLoading: boolean = false;

  constructor(private service: NewMancoService) {}

  handleSubmit(): void {
    this.isLoading = true;
    this.service.getOrderDetails(this.orderNumber).subscribe(
      (orderlines) => {
        this.orderDetails = orderlines;
        this.isLoading = false;
      },
      (error) => {
        console.error(
          'Failed to look up orderlines for order: ' + this.orderNumber,
          error
        );
        this.feedbackComponent.showFeedback(
          '',
          FeedbackType.error,
          'We konden de orderregels niet ophalen.',
          undefined,
          false
        );
        this.isLoading = false;
        this.fadeOutFeedback();
      }
    );
  }

  handleReset(): void {
    this.orderNumber = '';
    this.freshdeskTicketNumber = '';
    this.orderDetails = undefined;
  }

  fillOrderlines(): void {
    //TODO remove when upgrading this
    (this.orderNumber = 'obcc-test-00009857'),
      (this.freshdeskTicketNumber = '554699'),
      (this.orderDetails = {
        orderNumber: '123',
        orderDate: new Date('2024-11-19'),
        status: 'Confirmed',
        orderReference: 'Ref123',
        customerDetails: {
          customerNo: 'CUST-001',
        },
        orderlines: [
          {
            item: {
              sku: '10B1GPP005',
              description: 'Rollenketting 10B1',
              units: 'MTR',
            },
            quantityOrdered: 30,
            quantityReturned: 5,
            quantityCancelled: 5,
            stepQuantity: 5,
            deliveryStatus: {
              quantityInProcess: 5,
              expectedDeliveries: [
                {
                  quantity: 5,
                  deliveryDate: new Date('2024-11-20'),
                },
                {
                  quantity: 5,
                  deliveryDate: new Date('3333-03-03'),
                },
              ],
              deliveries: [
                {
                  quantity: 10,
                  deliveryDate: new Date('2024-11-19'),
                },
              ],
            },
          },
          {
            item: {
              sku: 'UB18DGLL0Z',
              description: 'Accu-bouwlamp UB18DGL L0Z 14.4-18V multivolt body',
              units: 'ea',
            },
            quantityOrdered: 1,
            quantityReturned: 0,
            quantityCancelled: 0,
            stepQuantity: 1,
            deliveryStatus: {
              quantityInProcess: 0,
              expectedDeliveries: [],
              deliveries: [
                {
                  quantity: 1,
                  deliveryDate: new Date('2024-11-22'),
                },
              ],
            },
          },
          {
            item: {
              sku: '23337',
              description: 'Oliepomp 10L/min 8bar 230V IP54',
              units: 'ea',
            },
            quantityOrdered: 30,
            quantityReturned: 10,
            quantityCancelled: 0,
            stepQuantity: 10,
            deliveryStatus: {
              quantityInProcess: 0,
              expectedDeliveries: [],
              deliveries: [
                {
                  quantity: 30,
                  deliveryDate: new Date('2024-11-24'),
                },
              ],
            },
          },
          {
            item: {
              sku: '23337',
              description: 'Oliepomp 10L/min 8bar 230V IP54',
              units: 'ea',
            },
            quantityOrdered: 30,
            quantityReturned: 30,
            quantityCancelled: 0,
            stepQuantity: 10,
            deliveryStatus: {
              quantityInProcess: 0,
              expectedDeliveries: [],
              deliveries: [
                {
                  quantity: 30,
                  deliveryDate: new Date('2024-11-24'),
                },
              ],
            },
          },
          {
            item: {
              sku: '23337',
              description: 'Oliepomp 10L/min 8bar 230V IP54',
              units: 'ea',
            },
            quantityOrdered: 30,
            quantityReturned: 0,
            quantityCancelled: 30,
            stepQuantity: 10,
            deliveryStatus: {
              quantityInProcess: 0,
              expectedDeliveries: [],
              deliveries: [
                {
                  quantity: 30,
                  deliveryDate: new Date('2024-11-24'),
                },
              ],
            },
          },
          {
            item: {
              sku: '23337',
              description: 'Oliepomp 10L/min 8bar 230V IP54',
              units: 'ea',
            },
            quantityOrdered: 30,
            quantityReturned: 15,
            quantityCancelled: 15,
            stepQuantity: 5,
            deliveryStatus: {
              quantityInProcess: 0,
              expectedDeliveries: [],
              deliveries: [
                {
                  quantity: 15,
                  deliveryDate: new Date('2024-11-24'),
                },
              ],
            },
          },
        ],
      });
  }

  fadeOutFeedback(): void {
    setTimeout(() => {
      this.feedbackComponent.fadeOut();
    }, 3000);
  }
}
