import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ORDER_HISTORY_SERVICE_URL } from 'src/app/injection-tokens';
import { OrderDetails } from './order-details';

@Injectable({
  providedIn: 'root',
})
export class NewMancoService {
  constructor(
    @Inject(ORDER_HISTORY_SERVICE_URL) readonly serviceURL: string,
    readonly http: HttpClient
  ) {}

  getOrderDetails(orderNumber: string): Observable<OrderDetails> {
    return this.http.get<OrderDetails>(
      this.serviceURL +
        'internal/orderhistory/orderdetails?orderNumber=' +
        orderNumber
    );
  }
}
