export interface KrampSpecial {
  id: number;
  sku: string;
  creationDate?: string;
  creationBy: string;
  supplier: string;
  description: string;
  krampSpecialCode: string;
  class4?: string;
  krampSupplierId?: number;
  krampSupplierName?: string; //readonly
  krampSupplierSku: string;
  purchasePrice?: number;
  supplierPrice?: number;
  salesPrice?: number;
  orderUnit: number;
  deliveryDays: number;
}

export const EmptyKrampSpecial = (): KrampSpecial => ({
  id: 1,
  sku: '',
  creationBy: '',
  supplier: 'SUPPLIER_KRAMP',
  description: '',
  krampSpecialCode: '',
  krampSupplierSku: '',
  orderUnit: 1,
  deliveryDays: 0,
});
