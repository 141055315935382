import { Component, Inject, Input } from '@angular/core';
import { BASE_INDI_URL } from 'src/app/injection-tokens';
import { OrderDetails, Orderline } from '../order-details';

@Component({
  selector: 'app-new-manco-orderlines-table',
  templateUrl: './new-manco-orderlines-table.component.html',
  styleUrls: ['./new-manco-orderlines-table.component.scss'],
})
export class NewMancoOrderlinesTableComponent {
  @Input() orderDetails!: OrderDetails;

  constructor(@Inject(BASE_INDI_URL) readonly baseIndiUrl: string) {}

  cannotReorder(orderline: Orderline): boolean {
    return (
      orderline.deliveryStatus.quantityInProcess === 0 &&
      orderline.quantityOrdered ===
        orderline.quantityCancelled + orderline.quantityReturned
    );
  }
}
