<h1 class="text-center border-bottom pb-3">Nieuwe manco</h1>

<div class="d-flex">
  <div class="col col-sm-4">
    <app-new-manco-form
      (orderNumberEmitter)="orderNumber = $event"
      (freshdeskTicketNumberEmitter)="freshdeskTicketNumber = $event"
      (submitEmitter)="handleSubmit()"
      (resetEmitter)="handleReset()"
    ></app-new-manco-form>
  </div>
  <div class="col col-sm-1">&nbsp;</div>
  <div class="col col-sm-7">
    <app-feedback></app-feedback>
    <div *ngIf="isLoading">
      <app-loading-gears></app-loading-gears>
    </div>
    <div *ngIf="orderDetails">
      <app-new-manco-order-info
        [orderDetails]="orderDetails"
        [freshdeskTicketNumber]="freshdeskTicketNumber"
      ></app-new-manco-order-info>
    </div>
  </div>
</div>
<button (click)="fillOrderlines()" *ngIf="isACCEnvironment">vullen</button>
<!-- TODO remove when upgrading this -->

<div>
  <div *ngIf="orderDetails">
    <app-new-manco-orderlines-table
      [orderDetails]="orderDetails"
    ></app-new-manco-orderlines-table>
  </div>
</div>
