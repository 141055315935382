import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-new-manco-form',
  templateUrl: './new-manco-form.component.html',
  styleUrls: ['./new-manco-form.component.scss'],
})
export class NewMancoFormComponent implements OnInit {
  @Output() orderNumberEmitter = new EventEmitter<string>();
  @Output() freshdeskTicketNumberEmitter = new EventEmitter<string>();
  @Output() submitEmitter = new EventEmitter<any>();
  @Output() resetEmitter = new EventEmitter<any>();
  form: FormGroup;

  constructor(private fb: FormBuilder, private route: ActivatedRoute) {
    this.form = this.fb.group({
      orderNumber: [
        '',
        [
          Validators.required,
          Validators.pattern('^(MNC-|SCSN-|ACC2-|obcc-test-)?0\\d{7}$'),
        ],
      ],
      freshdeskTicketNumber: [
        '',
        [Validators.required, Validators.pattern('^[0-9]{1,7}$')],
      ],
    });
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params) => {
      this.form.setValue({
        orderNumber: params.get('orderNumber') || '',
        freshdeskTicketNumber: params.get('freshdeskTicketNumber') || '',
      });
      this.onSubmit();
    });
  }

  onSubmit(): void {
    this.onReset();
    if (this.form.valid) {
      this.orderNumberEmitter.emit(this.form.value.orderNumber);
      this.freshdeskTicketNumberEmitter.emit(
        this.form.value.freshdeskTicketNumber
      );
      this.submitEmitter.emit();
    }
  }

  onReset(): void {
    this.resetEmitter.emit();
  }
}
