import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_BASE_HREF } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProfileComponent } from './general-components/profile/profile.component';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './general-components/home/home.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import { SuppliersComponent } from './service-components/specials/suppliers/suppliers.component';
import { SearchSuppliersPipe } from './service-components/specials/suppliers/search-suppliers.pipe';
import { environment } from 'src/environments/environment';
import { ReturnFailuresComponent } from './service-components/returns/return-failures/return-failures.component';
import { DispatchFailuresComponent } from './service-components/dispatches/dispatch-failures.component';
import { InvoiceSearchComponent } from './service-components/invoice-search/invoice-search.component';
import { PaymentTermsCheckerComponent } from './service-components/check-payment-terms/check-payment-terms.component';
import { PaymentTermsCustomersComponent } from './service-components/check-payment-terms-customers/check-payment-terms-customers.component';
import { MancosToCheckUpOnComponent } from './service-components/mancos/mancos-to-check-up-on/mancos-to-check-up-on.component';
import { MancosToCheckUpOnSearchPipe } from './service-components/mancos/mancos-to-check-up-on/mancos-to-check-up-on-search.pipe';
import { MancoFilterComponent } from './service-components/mancos/mancos-to-check-up-on/manco-filter/manco-filter.component';
import { MancoFilterPipe } from './service-components/mancos/mancos-to-check-up-on/manco-filter/manco-filter.pipe';
import { MancoDialogComponent } from './service-components/mancos/mancos-to-check-up-on/manco-dialog/manco-dialog.component';
import { MancoFormComponent } from './service-components/mancos/mancos-to-check-up-on/manco-dialog/manco-form/manco-form.component';
import { NewMancoComponent } from './service-components/mancos/new-manco/new-manco.component';
import { NewMancoFormComponent } from './service-components/mancos/new-manco/new-manco-form/new-manco-form.component';
import { NewMancoOrderInfoComponent } from './service-components/mancos/new-manco/new-manco-order-info/new-manco-order-info.component';
import { NewMancoOrderlinesTableComponent } from './service-components/mancos/new-manco/new-manco-orderlines-table/new-manco-orderlines-table.component';
import { DeliveryStatusColumnComponent } from './service-components/mancos/new-manco/new-manco-orderlines-table/delivery-status-column/delivery-status-column.component';
import { SOSEmailKrampComponent } from './service-components/sos-email/kramp/sos-email-kramp.component';
import { SOSEmailINDIComponent } from './service-components/sos-email/indi/sos-email-indi.component';
import { SOSEmailForOrdersWithoutResponseComponent } from './service-components/sos-email/indi-no-response/sos-email-indi-no-response.component';
import { SosCustomerListComponent } from './service-components/sos-email/sos-customer-list/sos-customer-list.component';
import { CurrencyFormatPipe } from './pipes/currency/currency-pipe';
import { OmsCustomerLinkPipe } from './pipes/oms-customer-link/oms-customer-link.pipe';
import { OmsOrderLinkPipe } from './pipes/oms-order-link/oms-order-link.pipe';
import { OmsInvoiceLinkPipe } from './pipes/oms-invoice-link/oms-invoice-link.pipe';
import { FreshdeskTicketLinkPipe } from './pipes/freshdesk-ticket-link/freshdesk-ticket-link.pipe';
import { CleanDatePipe } from './pipes/clean-date/clean-date.pipe';
import { KrampSpecialsComponent } from './service-components/specials/kramp-specials/kramp-specials.component';
import { SearchKrampSpecialsPipe } from './service-components/specials/kramp-specials/search-kramp-specials.pipe';
import { SearchStringArrayPipe } from './utils/search-string-array';
import { NewKrampSpecialComponent } from './service-components/specials/kramp-specials/new-kramp-special/new-kramp-special.component';
import { FeedbackComponent } from './general-components/feedback/feedback.component';
import { KrampSpecialsTableComponent } from './service-components/specials/kramp-specials/kramp-specials-table/kramp-specials-table.component';
import { FestoSpecialsComponent } from './service-components/specials/festo-specials/festo-specials.component';
import { NewFestoSpecialComponent } from './service-components/specials/festo-specials/new-festo-special/new-festo-special.component';
import { FestoSpecialsTableComponent } from './service-components/specials/festo-specials/festo-specials-table/festo-specials-table.component';
import { SearchFestoSpecialsPipe } from './service-components/specials/festo-specials/search-festo-specials.pipe';
import { TUSpecialsComponent } from './service-components/specials/tu-specials/tu-specials.component';
import { NewTUSpecialComponent } from './service-components/specials/tu-specials/new-tu-special/new-tu-special.component';
import { TUSpecialsTableComponent } from './service-components/specials/tu-specials/tu-specials-table/tu-specials-table.component';
import { SearchTUSpecialsPipe } from './service-components/specials/tu-specials/search-tu-specials.pipe';
import { INDISpecialsComponent } from './service-components/specials/indi-specials/indi-specials.component';
import { INDISpecialsTableComponent } from './service-components/specials/indi-specials/indi-specials-table/indi-specials-table.component';
import { NewINDISpecialComponent } from './service-components/specials/indi-specials/new-indi-special/new-indi-special.component';
import { SearchINDISpecialsPipe } from './service-components/specials/indi-specials/search-indi-specials.pipe';
import { PriceCheckerComponent } from './service-components/price-checker/price-checker.component';
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import { QuoteLineComponent } from './service-components/price-checker/quote-line/quote-line.component';
import { QuoteTableComponent } from './service-components/price-checker/quote-table/quote-table.component';
import { ReturnsComponent } from './service-components/returns/returns/returns.component';
import { ReturnItemLineComponent } from './service-components/returns/returns/return-item-line/return-item-line.component';
import { ReturnUpdateFeedbackComponent } from './service-components/returns/returns/return-item-line/return-update-feedback/return-update-feedback.component';
import { TooltipModule } from './general-components/tooltip/tooltip.module';
import { ProcessedFilesComponent } from './service-components/returns/returns/processed-files/processed-files.component';
import { utcDatePipe } from './pipes/utc-date/utc-date.pipe';
import { KrampShippingAddressesComponent } from './service-components/kramp-shipping-addresses/kramp-shipping-addresses.component';
import { SearchKrampShippingAddresses } from './service-components/kramp-shipping-addresses/search-kramp-shipping-addresses.pipe';
import { KrampShippingAddressesTableComponent } from './service-components/kramp-shipping-addresses/kramp-shipping-addresses-table/kramp-shipping-addresses-table.component';
import { YesNoPipe } from './pipes/translate-boolean/yes-no.pipe';
import { KrampShippingAddressFormComponent } from './service-components/kramp-shipping-addresses/kramp-shipping-address-form/kramp-shipping-address-form.component';
import { ConnectionFeedbackComponent } from './general-components/connection-status/connection-feedback.component';
import { RemoveValidCharactersPipe } from './pipes/remove-valid-characters/remove-valid-characters.pipe';
import { ScsnCustomersComponent } from './service-components/scsn-customers/scsn-customers.component';
import { ScsnCustomersTableComponent } from './service-components/scsn-customers/scsn-customers-table/scsn-customers-table.component';
import { SearchExtendedSCSNCustomers } from './service-components/scsn-customers/search-scsn-customers.pipe';
import { IcmCustomerLinkPipe } from './pipes/icm-customer-link/icm-customer-link.pipe';
import { ScsnCustomersFormComponent } from './service-components/scsn-customers/scsn-customers-form/scsn-customers-form.component';
import { CustomerInformationComponent } from './service-components/scsn-customers/scsn-customers-form/customer-information/customer-information.component';
import { LoadingWrenchesComponent } from './general-components/loading/loading-wrenches/loading-wrenches.component';
import { LoadingGearsComponent } from './general-components/loading/loading-gears/loading.component';

registerLocaleData(localeNl);

@NgModule({ declarations: [
        AppComponent,
        ProfileComponent,
        SuppliersComponent,
        HomeComponent,
        FeedbackComponent,
        LoadingGearsComponent,
        SearchSuppliersPipe,
        ReturnFailuresComponent,
        DispatchFailuresComponent,
        InvoiceSearchComponent,
        PaymentTermsCheckerComponent,
        PaymentTermsCustomersComponent,
        MancosToCheckUpOnComponent,
        MancosToCheckUpOnSearchPipe,
        MancoFilterComponent,
        MancoFilterPipe,
        MancoDialogComponent,
        MancoFormComponent,
        NewMancoComponent,
        NewMancoFormComponent,
        NewMancoOrderInfoComponent,
        NewMancoOrderlinesTableComponent,
        DeliveryStatusColumnComponent,
        SOSEmailKrampComponent,
        SOSEmailINDIComponent,
        SOSEmailForOrdersWithoutResponseComponent,
        CurrencyFormatPipe,
        SosCustomerListComponent,
        OmsCustomerLinkPipe,
        OmsOrderLinkPipe,
        OmsInvoiceLinkPipe,
        FreshdeskTicketLinkPipe,
        CleanDatePipe,
        KrampSpecialsComponent,
        SearchKrampSpecialsPipe,
        SearchStringArrayPipe,
        NewKrampSpecialComponent,
        KrampSpecialsTableComponent,
        FestoSpecialsComponent,
        NewFestoSpecialComponent,
        FestoSpecialsTableComponent,
        SearchFestoSpecialsPipe,
        TUSpecialsComponent,
        NewTUSpecialComponent,
        TUSpecialsTableComponent,
        SearchTUSpecialsPipe,
        INDISpecialsComponent,
        INDISpecialsTableComponent,
        NewINDISpecialComponent,
        SearchINDISpecialsPipe,
        PriceCheckerComponent,
        QuoteLineComponent,
        QuoteTableComponent,
        ReturnsComponent,
        ReturnItemLineComponent,
        ReturnUpdateFeedbackComponent,
        ProcessedFilesComponent,
        KrampShippingAddressesComponent,
        SearchKrampShippingAddresses,
        utcDatePipe,
        RemoveValidCharactersPipe,
        KrampShippingAddressesTableComponent,
        YesNoPipe,
        KrampShippingAddressFormComponent,
        ConnectionFeedbackComponent,
        ScsnCustomersComponent,
        ScsnCustomersTableComponent,
        SearchExtendedSCSNCustomers,
        IcmCustomerLinkPipe,
        ScsnCustomersFormComponent,
        CustomerInformationComponent,
        LoadingWrenchesComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        NgxPaginationModule,
        FormsModule,
        ReactiveFormsModule,
        TooltipModule,
        OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: environment.allowedResourceServerUrls,
                sendAccessToken: true,
            },
        })], providers: [
        {
            provide: APP_BASE_HREF,
            useValue: '/',
        },
        { provide: LOCALE_ID, useValue: 'nl-NL' },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
