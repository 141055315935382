import { formatDate } from '@angular/common';

export interface MancosToCheckUpOn {
  nrOfMancos: number;
  mancos: MancoToCheckUpOn[];
}
export interface Manco {
  caseNumber: string;
  customerNumber: string;
  ticketNumber: string;
  treatmentDate: string;
  checkDate: string;
  booked: boolean;
  completed: boolean;
}
export interface MancoToCheckUpOn extends Manco {
  id: number;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}
export interface MancoToAdd extends Manco {
  createdByHint: string;
}
export interface MancoToUpdate extends Manco {
  updatedByHint: string;
}
export interface AddedMancoId {
  id: string;
}

export const EmptyMancoToCheckUpOn = (): Manco => ({
  ticketNumber: '',
  customerNumber: '',
  caseNumber: '',
  treatmentDate: formatDate(new Date(), 'yyyy-MM-dd', 'nl-NL'),
  checkDate: formatDate(
    new Date().setDate(new Date().getDate() + 14),
    'yyyy-MM-dd',
    'nl-NL'
  ),
  booked: false,
  completed: false,
});
