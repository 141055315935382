<h1 class="text-center border-bottom pb-3">Nog te checken Manco's</h1>
<div
  class="text-center my-3"
  [ngClass]="{ 'd-none': failedToLoad || mancosToCheckUpOn.mancos.length > 0 }"
>
  Wij gaan voor je kijken of er Manco's zijn welke nog opvolging nodig hebben.
</div>

<div>
  <app-feedback (update)="returnHome()"></app-feedback>
</div>

<div class="row" [ngClass]="{ 'd-none': isLoading || failedToLoad }">
  <div
    class="d-flex justify-content-between align-items-center mb-2 filter-bar"
  >
    <div class="d-flex">
      <app-manco-filter
        (selectedFilterChange)="onSelectedFilterChange($event)"
      ></app-manco-filter>

      <button class="btn btn-primary mx-2" (click)="onAddClick()">
        Toevoegen
      </button>
    </div>
    <div class="searchbar">
      <input
        class="search_input"
        type="text"
        [(ngModel)]="searchText"
        (ngModelChange)="onSearchTextChange()"
        placeholder="Zoeken..."
      />
      <div class="search_icon"><i class="fas fa-search"></i></div>
    </div>
  </div>
  <div *ngIf="mancosToCheckUpOn.mancos.length > 0; else noResults">
    <table class="table table-hover" [ngClass]="{ 'd-none': failedToLoad }">
      <thead class="sticky">
        <tr>
          <th scope="col" class="width-5">Ticket</th>
          <th scope="col" class="width-15">Klant</th>
          <th scope="col" class="width-20">Kramp case</th>
          <th scope="col" class="width-5">Datum</th>
          <th scope="col" class="width-5">Opvolging</th>
          <th scope="col" class="width-5">
            <span data-toggle="tooltip" title="Opgeboekt in voorraad">O</span>
          </th>
          <th scope="col" class="width-5">
            <span data-toggle="tooltip" title="Afgehandeld">A</span>
          </th>
          <th scope="col" class="width-5">Actie</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let manco of mancosToCheckUpOn.mancos
              | mancosToCheckUpOnSearch : searchText
              | mancoFilter : selectedFilter
              | paginate : { itemsPerPage: 100, currentPage: page }
          "
        >
          <td>
            <a
              href="{{ manco.ticketNumber | freshdeskTicketLink }}"
              target="_blank"
              >{{ manco.ticketNumber }}</a
            >
          </td>
          <td>{{ manco.customerNumber }}</td>
          <td>{{ manco.caseNumber }}</td>
          <td>
            {{ manco.treatmentDate | cleanDate : "date" }}
          </td>
          <td>
            {{ manco.checkDate | cleanDate : "date" }}
          </td>
          <td>{{ manco.booked ? "X" : "-" }}</td>
          <td>{{ manco.completed ? "X" : "-" }}</td>
          <td>
            <div>
              <button
                type="button"
                class="btn btn-outline-primary btn-sm me-3"
                (click)="onEditClick(manco)"
              >
                <span class="d-xxl-none"
                  ><i class="fa-solid fa-pencil"></i
                ></span>
                <span class="d-none d-xxl-inline"> {{ "Aanpassen" }}</span>
              </button>
            </div>
          </td>
          <!-- TODO: Add something with mutation tracking here -->
        </tr>
      </tbody>
    </table>
    <pagination-controls
      (pageChange)="page = $event"
      previousLabel="Vorige"
      nextLabel="Volgende"
      [autoHide]="true"
    ></pagination-controls>
  </div>
  <ng-template #noResults>
    <div
      class="d-flex justify-content-center mt-5"
      [ngClass]="{ 'd-none': failedToLoad }"
    >
      <div class="p-5 border">
        <img [src]="'assets/cat.png'" height="100" />
        <span class="ms-4 fs-4"> Goed bezig, de lijst is leeg! </span>
      </div>
    </div>
  </ng-template>
</div>
<div [ngClass]="{ 'd-none': !isLoading }">
  <app-loading-gears></app-loading-gears>
</div>
<app-manco-dialog></app-manco-dialog>
