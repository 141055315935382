import { NgModule, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterModule,
  RouterStateSnapshot,
  Routes,
} from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { HomeComponent } from './general-components/home/home.component';
import { ProfileComponent } from './general-components/profile/profile.component';
import { SuppliersComponent } from './service-components/specials/suppliers/suppliers.component';
import { ReturnFailuresComponent } from './service-components/returns/return-failures/return-failures.component';
import { DispatchFailuresComponent } from './service-components/dispatches/dispatch-failures.component';
import { InvoiceSearchComponent } from './service-components/invoice-search/invoice-search.component';
import { PaymentTermsCheckerComponent } from './service-components/check-payment-terms/check-payment-terms.component';
import { PaymentTermsCustomersComponent } from './service-components/check-payment-terms-customers/check-payment-terms-customers.component';
import { MancosToCheckUpOnComponent } from './service-components/mancos/mancos-to-check-up-on/mancos-to-check-up-on.component';
import { NewMancoComponent } from './service-components/mancos/new-manco/new-manco.component';
import { SOSEmailKrampComponent } from './service-components/sos-email/kramp/sos-email-kramp.component';
import { SOSEmailINDIComponent } from './service-components/sos-email/indi/sos-email-indi.component';
import { SOSEmailForOrdersWithoutResponseComponent } from './service-components/sos-email/indi-no-response/sos-email-indi-no-response.component';
import { KrampSpecialsComponent } from './service-components/specials/kramp-specials/kramp-specials.component';
import { FestoSpecialsComponent } from './service-components/specials/festo-specials/festo-specials.component';
import { TUSpecialsComponent } from './service-components/specials/tu-specials/tu-specials.component';
import { INDISpecialsComponent } from './service-components/specials/indi-specials/indi-specials.component';
import { PriceCheckerComponent } from './service-components/price-checker/price-checker.component';
import { ReturnsComponent } from './service-components/returns/returns/returns.component';
import { KrampShippingAddressesComponent } from './service-components/kramp-shipping-addresses/kramp-shipping-addresses.component';
import { ScsnCustomersComponent } from './service-components/scsn-customers/scsn-customers.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },

  { path: 'profiel', component: ProfileComponent, canActivate: [AuthGuard] },
  {
    path: 'leveranciers',
    component: SuppliersComponent,
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        inject(AuthGuard).canActivate(route, state),
    ],
  },
  {
    path: 'retour-uitval',
    component: ReturnFailuresComponent,
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        inject(AuthGuard).canActivate(route, state),
    ],
  },
  {
    path: 'retouren',
    component: ReturnsComponent,
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        inject(AuthGuard).canActivate(route, state),
    ],
  },
  {
    path: 'dispatches',
    component: DispatchFailuresComponent,
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        inject(AuthGuard).canActivate(route, state),
    ],
  },
  {
    path: 'factuur-zoeken',
    component: InvoiceSearchComponent,
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        inject(AuthGuard).canActivate(route, state),
    ],
  },
  {
    path: 'specials-kramp',
    component: KrampSpecialsComponent,
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        inject(AuthGuard).canActivate(route, state),
    ],
  },
  {
    path: 'specials-festo',
    component: FestoSpecialsComponent,
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        inject(AuthGuard).canActivate(route, state),
    ],
  },
  {
    path: 'specials-tu',
    component: TUSpecialsComponent,
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        inject(AuthGuard).canActivate(route, state),
    ],
  },
  {
    path: 'specials-indi',
    component: INDISpecialsComponent,
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        inject(AuthGuard).canActivate(route, state),
    ],
  },
  {
    path: 'betaaltermijnen-checken',
    component: PaymentTermsCheckerComponent,
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        inject(AuthGuard).canActivate(route, state),
    ],
  },
  {
    path: 'betaaltermijnen-klanten',
    component: PaymentTermsCustomersComponent,
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        inject(AuthGuard).canActivate(route, state),
    ],
  },
  {
    path: 'price-checker',
    component: PriceCheckerComponent,
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        inject(AuthGuard).canActivate(route, state),
    ],
  },
  {
    path: 'mancos/mancos-to-check-up-on',
    component: MancosToCheckUpOnComponent,
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        inject(AuthGuard).canActivate(route, state),
    ],
  },
  {
    path: 'mancos/new-manco',
    component: NewMancoComponent,
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        inject(AuthGuard).canActivate(route, state),
    ],
  },
  {
    path: 'sos-email-kramp',
    component: SOSEmailKrampComponent,
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        inject(AuthGuard).canActivate(route, state),
    ],
  },
  {
    path: 'sos-email-indi',
    component: SOSEmailINDIComponent,
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        inject(AuthGuard).canActivate(route, state),
    ],
  },
  {
    path: 'sos-email-no-response',
    component: SOSEmailForOrdersWithoutResponseComponent,
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        inject(AuthGuard).canActivate(route, state),
    ],
  },
  {
    path: 'kramp-verzend-adressen',
    component: KrampShippingAddressesComponent,
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        inject(AuthGuard).canActivate(route, state),
    ],
  },
  {
    path: 'scsn-klanten',
    component: ScsnCustomersComponent,
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        inject(AuthGuard).canActivate(route, state),
    ],
  },
  { path: '', component: HomeComponent }, //these need to be at the end
  { path: '**', redirectTo: 'home' }, //these need to be at the end
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
