<div>
  <div *ngFor="let delivery of orderline.deliveryStatus.deliveries">
    <div>
      <p>
        <i class="fa fa-check green"></i>
        ({{ delivery.quantity }}) Verstuurd op
        {{ delivery.deliveryDate.toString() | date : "dd/MM/yyyy" }}
      </p>
    </div>
  </div>
  <div *ngIf="orderline.deliveryStatus.quantityInProcess > 0">
    <p>
      <i class="fa fa-hourglass dark"></i>
      ({{ orderline.deliveryStatus.quantityInProcess }}) In behandeling
    </p>
  </div>
  <div
    *ngFor="let expectedDelivery of orderline.deliveryStatus.expectedDeliveries"
  >
    <p>
      <i
        class="fa"
        [class.fa-calendar-xmark]="IsDateUnknown(expectedDelivery.deliveryDate)"
        [class.fa-calendar-day]="!IsDateUnknown(expectedDelivery.deliveryDate)"
      ></i>
      ({{ expectedDelivery.quantity }}) Leverdatum
      {{
        IsDateUnknown(expectedDelivery.deliveryDate)
          ? "onbekend"
          : (expectedDelivery.deliveryDate | date : "dd/MM/yyyy")
      }}
    </p>
  </div>
  <div *ngIf="orderline.quantityReturned > 0">
    <p>
      <i class="fa fa-reply"></i>
      ({{ orderline.quantityReturned }}) Geretourneerd
    </p>
  </div>
  <div *ngIf="orderline.quantityCancelled > 0">
    <p>
      <i class="fa fa-times-circle red"></i>
      ({{ orderline.quantityCancelled }}) Geannuleerd
    </p>
  </div>
</div>
