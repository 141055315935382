<form [formGroup]="form" (ngSubmit)="onSubmit()" class="needs-validation">
  <div class="dialog-body p-3">
    <!-- Freshdesk Ticket Number -->
    <div class="mb-3 row">
      <label for="ticketnummer" class="col-sm-4 col-form-label"
        >Freshdesk ticket:</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          class="form-control form-control-sm"
          id="ticketnummer"
          formControlName="ticketNumber"
          #ticketNumberInput
        />
        <div
          *ngIf="
            form.get('ticketNumber')?.invalid &&
            (form.get('ticketNumber')?.dirty ||
              form.get('ticketNumber')?.touched)
          "
          class="text-danger"
        >
          <div *ngIf="form.get('ticketNumber')?.errors?.['required']">
            Freshdesk ticket is verplicht.
          </div>
          <div *ngIf="form.get('ticketNumber')?.errors?.['pattern']">
            Freshdesk ticket mogen alleen cijfers zijn.
          </div>
        </div>
      </div>
    </div>

    <!-- Client Number -->
    <div class="mb-3 row">
      <label for="klantnummer" class="col-sm-4 col-form-label"
        >Klantnummer:</label
      >
      <div class="col-sm-8">
        <input
          type="text"
          class="form-control form-control-sm"
          id="klantnummer"
          formControlName="customerNumber"
        />
        <div
          *ngIf="
            form.get('customerNumber')?.invalid &&
            (form.get('customerNumber')?.dirty ||
              form.get('customerNumber')?.touched)
          "
          class="text-danger"
        >
          <div *ngIf="form.get('customerNumber')?.errors?.['maxlength']">
            Klantnummer mag niet langer zijn van 20.
          </div>
          <div *ngIf="form.get('customerNumber')?.errors?.['pattern']">
            Klantnummer moet een INDI klantnummer zijn.
          </div>
        </div>
      </div>
    </div>

    <!-- Kramp case -->
    <div class="mb-3 row">
      <label for="case" class="col-sm-4 col-form-label">Kramp case:</label>
      <div class="col-sm-8">
        <input
          type="text"
          class="form-control form-control-sm"
          id="case"
          formControlName="caseNumber"
        />
        <div
          *ngIf="
            form.get('caseNumber')?.invalid &&
            (form.get('caseNumber')?.dirty || form.get('caseNumber')?.touched)
          "
          class="text-danger"
        >
          <div *ngIf="form.get('caseNumber')?.errors?.['maxlength']">
            Kramp case mag niet langer zijn van 20.
          </div>
        </div>
      </div>
    </div>

    <!-- Treatment Date -->
    <div class="mb-3 row">
      <label for="behandeldatum" class="col-sm-4 col-form-label"
        >Behandeldatum:</label
      >
      <div class="col-sm-8">
        <input
          type="date"
          max="2030-12-31"
          class="form-control form-control-sm"
          id="behandeldatum"
          formControlName="treatmentDate"
        />
        <div
          *ngIf="
            form.get('treatmentDate')?.invalid &&
            (form.get('treatmentDate')?.dirty ||
              form.get('treatmentDate')?.touched)
          "
          class="text-danger"
        >
          <div *ngIf="form.get('treatmentDate')?.errors?.['required']">
            Behandeldatum is verplicht.
          </div>
        </div>
      </div>
    </div>

    <!-- Review Date -->
    <div class="mb-3 row">
      <label for="nakijken" class="col-sm-4 col-form-label">Nakijken na:</label>
      <div class="col-sm-8">
        <input
          type="date"
          max="2030-12-31"
          class="form-control form-control-sm"
          id="nakijken"
          formControlName="checkDate"
        />
        <div
          *ngIf="
            form.get('checkDate')?.invalid &&
            (form.get('checkDate')?.dirty || form.get('checkDate')?.touched)
          "
          class="text-danger"
        >
          <div *ngIf="form.get('checkDate')?.errors?.['required']">
            'Nakijken na' is verplicht.
          </div>
        </div>
      </div>
    </div>

    <!-- Checkboxes -->
    <div class="mb-3 row">
      <div class="col-sm-8 offset-sm-4">
        <div class="form-check mb-2">
          <input
            type="checkbox"
            class="form-check-input"
            id="opgeboekt"
            formControlName="booked"
          />
          <label class="form-check-label" for="opgeboekt"
            >Opgeboekt in voorraad <strong>(O)</strong></label
          >
        </div>
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            id="afgehandeld"
            formControlName="completed"
          />
          <label class="form-check-label" for="afgehandeld"
            >Afgehandeld <strong>(A)</strong></label
          >
        </div>
      </div>
    </div>
  </div>

  <div
    class="dialog-footer d-flex gap-2 justify-content-end p-3 bg-light border-top"
  >
    <button type="submit" class="btn btn-primary" [disabled]="!form.valid">
      Opslaan
    </button>
  </div>
</form>
