import { Component, Input } from '@angular/core';
import { Orderline } from '../../order-details';

@Component({
  selector: 'app-delivery-status-column',
  templateUrl: './delivery-status-column.component.html',
  styleUrls: ['./delivery-status-column.component.scss'],
})
export class DeliveryStatusColumnComponent {
  readonly UNKNOWN_DELIVERY_DATE: Date = new Date('3333-03-03');
  @Input() orderline!: Orderline;

  IsDateUnknown(date: Date): boolean {
    return date.toString() === this.UNKNOWN_DELIVERY_DATE.toString();
  }
}
