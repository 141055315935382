<table class="table table-hover">
  <thead>
    <tr>
      <th scope="col" class="width-5">Herorder</th>
      <th scope="col" class="width-5">Aantal</th>
      <th scope="col" class="width-15">SKU</th>
      <th scope="col" class="width-20">Productnaam</th>
      <th scope="col" class="width-5">Besteld</th>
      <th scope="col" class="width-20">Orderregelstatus</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let orderline of orderDetails.orderlines; let i = index">
      <td>
        <input
          type="checkbox"
          class="form-check-input"
          [disabled]="cannotReorder(orderline)"
        />
      </td>
      <td class="pt-1">
        <input
          type="text"
          class="form-control form-control-sm"
          placeholder="aantal"
          [disabled]="cannotReorder(orderline)"
        />
      </td>
      <td>
        <a
          class="c-block-media__url"
          href="{{ baseIndiUrl }}nl-nl/p/{{ orderline.item.sku }}"
          target="_blank"
          >{{ orderline.item.sku }}
        </a>
      </td>
      <td>
        <a
          class="c-block-media__url"
          href="{{ baseIndiUrl }}nl-nl/p/{{ orderline.item.sku }}"
          target="_blank"
          >{{ orderline.item.description }}
        </a>
      </td>
      <td>{{ orderline.quantityOrdered }} {{ orderline.item.units }}</td>
      <td>
        <app-delivery-status-column
          [orderline]="orderline"
        ></app-delivery-status-column>
      </td>
    </tr>
  </tbody>
</table>
<div class="d-flex justify-content-between">
  <div class="d-flex flex-column w-50">
    Reden opnieuw bestellen:
    <select class="form-select">
      <option>Optie 1</option>
      <option>Optie 2</option>
    </select>
    <div class="d-flex flex-column mt-4">
      Notitie
      <textarea
        class="form-control"
        placeholder="In geval van speciale redenen, geef hier aan waarom deze manco is aangemaakt"
        rows="8"
        maxlength="2000"
      ></textarea>
    </div>
  </div>

  <div class="d-flex h-100">
    <button class="btn btn-outline-primary">
      <i class="fa-regular fa-shopping-cart fa-l"></i> Manco aanmaken
    </button>
  </div>
</div>
