import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { Profile } from 'src/app/general-components/profile/profile';
import { FeedbackComponent } from 'src/app/general-components/feedback/feedback.component';
import { FeedbackType } from 'src/app/general-components/feedback/feedback-type';
import { MancosToCheckUpOnService } from './mancos-to-check-up-on.service';
import {
  EmptyMancoToCheckUpOn,
  Manco,
  MancosToCheckUpOn,
  MancoToAdd,
  MancoToCheckUpOn,
  MancoToUpdate,
} from './manco-to-check-up-on';
import { MancoDialogComponent } from './manco-dialog/manco-dialog.component';
import { MancoFilters } from './manco-filter/manco-filter.pipe';

@Component({
  selector: 'app-mancos-to-check-up-on',
  templateUrl: './mancos-to-check-up-on.component.html',
  styleUrls: ['./mancos-to-check-up-on.component.scss'],
})
export class MancosToCheckUpOnComponent implements OnInit {
  mancosToCheckUpOn: MancosToCheckUpOn = { nrOfMancos: 0, mancos: [] };
  page: number = 1;
  searchText: string = '';
  selectedFilter: MancoFilters = MancoFilters.AllMancos;

  profile: Profile | undefined;

  isLoading: boolean = false;
  failedToLoad: boolean = false;

  @ViewChild(FeedbackComponent)
  feedbackComponent!: FeedbackComponent;

  @ViewChild(MancoDialogComponent) dialog!: MancoDialogComponent;

  constructor(
    private mancosToCheckUpOnService: MancosToCheckUpOnService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getMancosToCheckUpOn();
    this.profile = this.authService.getUserInfo();
  }

  getMancosToCheckUpOn(): void {
    this.isLoading = true;
    this.mancosToCheckUpOnService.getMancosToCheckUpOn().subscribe(
      (mancosToCheckUpOn) => {
        this.mancosToCheckUpOn = mancosToCheckUpOn;
        this.isLoading = false;
      },
      (error) => {
        console.error('Failed to add manco:', error);
        this.feedbackComponent.showFeedback(
          '',
          FeedbackType.error,
          "We konden de Manco's niet ophalen.",
          'Terug naar hoofdmenu',
          false
        );
        this.isLoading = false;
        this.failedToLoad = true;
      }
    );
  }

  async onAddClick(): Promise<void> {
    let newManco: Manco = EmptyMancoToCheckUpOn();
    const result: Manco | undefined = await this.dialog.showModal(newManco);

    if (result) {
      const mancoToAdd: MancoToAdd = {
        ...result,
        createdByHint: `${this.profile?.firstName} ${this.profile?.lastName}`,
      };

      this.mancosToCheckUpOnService.addMancoToCheckUpOn(mancoToAdd).subscribe({
        next: (addedMancoId) => {
          this.getMancosToCheckUpOn();
        },
        error: (error) => {
          console.error('Failed to add manco:', error);
          this.feedbackComponent.showFeedback(
            '',
            FeedbackType.error,
            'We konden de nieuwe manco niet aanmaken.',
            undefined,
            false
          );
          this.fadeOutFeedback();
        },
      });
    }
  }

  async onEditClick(manco: MancoToCheckUpOn): Promise<void> {
    const result: Manco | undefined = await this.dialog.showModal(manco);

    if (result) {
      const mancoToUpdate: MancoToUpdate = {
        ...result,
        updatedByHint: `${this.profile?.firstName} ${this.profile?.lastName}`,
      };

      this.mancosToCheckUpOnService
        .updateMancoToCheckUpOn(manco.id, mancoToUpdate)
        .subscribe({
          next: () => {
            this.getMancosToCheckUpOn();
          },
          error: (error) => {
            console.error('Failed to update manco:', error);
            this.feedbackComponent.showFeedback(
              '',
              FeedbackType.error,
              'We konden de manco niet bewerken.',
              undefined,
              false
            );
            this.fadeOutFeedback();
          },
        });
    }
  }

  onSearchTextChange(): void {
    this.page = 1;
  }

  onSelectedFilterChange(filter: MancoFilters): void {
    this.selectedFilter = filter;
  }

  returnHome(): void {
    this.router.navigate(['home']);
  }

  fadeOutFeedback(): void {
    setTimeout(() => {
      this.feedbackComponent.fadeOut();
    }, 3000);
  }
}
